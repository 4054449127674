import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  CampaignDataProps,
  CampaignSettingsProps,
  InitialSettingsFormikValuesProps,
} from './components/startCampaignModels';

import {
  createCampaign,
  getAgents,
  updateCampaign,
} from '../../core/requests/requests';
import {
  campaignLinkValidationSchema,
  campaignNameValidationSchema,
  platformValidationSchema,
} from '../auth/components/ValidationSchemaItems';
import {
  CampaignPlatformDropdownConfig,
  CampaignTimeZonesDropdownConfig,
  CampaignToneDropdownConfig,
  campaignLaunchTimeConfig,
  campaignViewValues,
} from '../../config/CampaignsConfig';
import { CalendarBlackIcon, ChevronDownIcon, InfoIcon } from '../../assets';
import {
  convertTimeTo12HourFormat,
  extractGMTOffset,
  extractInfoInParentheses,
  getFormatTimeWithoutPeriod,
  getFormattedStringFromDate,
  getGMTStringFromOffset,
  isAM,
} from '../../helpers/campaignHelpers';
import DateRangeSelector from './components/DateRangeSelector';
import { useAppData } from '../../context/AppContext';
import { InfoModal } from '../../components';
import AgentSelectionModal from './components/AgentSelectionModal';
import AdCampaignSelectionModal from './components/AdCampaignSelectionModal';
import { AgentProps } from '../../core/types/_models';

const CampaignSettings: React.FC<CampaignSettingsProps> = ({
  setCampaignData,
}) => {
  const {
    setCurrentStartCampaignView,
    setShowModalBackground,
    setCurrentCampaign,
    currentCampaign,
  } = useAppData();

  const initialFormikValues: InitialSettingsFormikValuesProps = {
    name: currentCampaign?.name ?? '',
    startDate: currentCampaign?.startDate
      ? getFormattedStringFromDate(new Date(currentCampaign?.startDate))
      : getFormattedStringFromDate(new Date()),
    endDate: currentCampaign?.endDate
      ? getFormattedStringFromDate(new Date(currentCampaign?.endDate))
      : getFormattedStringFromDate(
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        ),
    campaignLaunch: currentCampaign?.launchTime ?? '08:00:00',
    timezone:
      getGMTStringFromOffset(
        currentCampaign?.timeZone ? currentCampaign?.timeZone : 0
      ) ?? '(GMT-7)',
    link: currentCampaign?.productLink ?? '',
    goalAndFocus: currentCampaign?.goals ?? '',
    toneSelection: currentCampaign?.tone ?? 'Empathetic',
    backgroundInformation: currentCampaign?.backgroundInfo ?? '',
    platforms: currentCampaign?.platforms ?? [],
  };

  const [formikValues, setFormikValues] =
    useState<InitialSettingsFormikValuesProps>(initialFormikValues);
  const [isTimeZoneDropdownOpen, setIsTimeZoneDropdownOpen] = useState(false);
  const [isToneDropdownOpen, setIsToneDropdownOpen] = useState(false);
  const [isPlatformDropdownOpen, setIsPlatformDropdownOpen] = useState(false);
  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);
  const [isTimeSelectorOpen, setIsTimeSelectorOpen] = useState(false);
  const [isAgentSelectionModalOpen, setIsAgentSelectionModalOpen] =
    useState(false);
  const [isAdCampaignSelectionModalOpen, setIsAdCampaignSelectionModalOpen] =
    useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const timeZoneOptions: string[] = CampaignTimeZonesDropdownConfig.timeZones;
  const [chosenAgentId, setChosenAgentId] = useState<string | null>(
    currentCampaign?.agentId ?? null
  );
  const [agentsData, setAgentsData] = useState<AgentProps[]>([]);

  const handleShowUpdateModal = () => {
    setShowModalBackground(true);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowModalBackground(false);
    setShowUpdateModal(false);
  };

  const handleShowAgentSelectionModal = () => {
    setIsAgentSelectionModalOpen(true);
    setShowModalBackground(true);
  };

  const handleCloseAgentSelectionModal = () => {
    setIsAgentSelectionModalOpen(false);
    setShowModalBackground(false);
  };

  const handleShowAdCampaignSelectionModal = () => {
    setShowModalBackground(true);
    setIsAdCampaignSelectionModalOpen(true);
  };

  const handleCloseAdCampaignSelectionModal = () => {
    setIsAdCampaignSelectionModalOpen(false);
    setShowModalBackground(false);
  };

  const handleSubmit = async (values: InitialSettingsFormikValuesProps) => {
    try {
      if (currentCampaign) {
        const updatedPayload: CampaignDataProps = {
          ...currentCampaign,
          name: values.name,
          agentId: chosenAgentId,
          startDate: formikValues?.startDate,
          endDate: formikValues?.endDate,
          launchTime: formikValues?.campaignLaunch,
          timeZone: extractGMTOffset(
            formikValues?.timezone ? formikValues?.timezone : '(GMT+0)'
          ),
          productLink: values.link,
          goals: values.goalAndFocus,
          tone: formikValues?.toneSelection,
          backgroundInfo: values.backgroundInformation,
          platforms: values?.platforms,
        };

        const fetchedCampaignData = await updateCampaign(updatedPayload);
        if (fetchedCampaignData?.data) {
          handleShowUpdateModal();
          setTimeout(() => {
            setCampaignData(fetchedCampaignData?.data);
            setCurrentCampaign(fetchedCampaignData?.data ?? '');
            setCurrentStartCampaignView(campaignViewValues?.startCampaign);
            handleCloseUpdateModal();
          }, 1500);
        }
      } else {
        const upperCasePlatforms = values?.platforms.map((platform) =>
          platform?.toUpperCase()
        );
        const updatedPayload: CampaignDataProps = {
          agentId: chosenAgentId,
          phoneId: null,
          region: 'us',
          name: values.name,
          startDate: formikValues?.startDate,
          endDate: formikValues?.endDate,
          launchTime: formikValues?.campaignLaunch,
          timeZone: extractGMTOffset(
            formikValues?.timezone ? formikValues?.timezone : '(GMT+0)'
          ),
          productLink: values.link,
          goals: values.goalAndFocus,
          tone: formikValues?.toneSelection,
          backgroundInfo: values.backgroundInformation,
          type: '',
          platforms: upperCasePlatforms,
        };
        const fetchedCampaignData = await createCampaign(updatedPayload);
        if (fetchedCampaignData?.data) {
          setCampaignData(fetchedCampaignData?.data);
          setCurrentStartCampaignView(campaignViewValues?.startCampaign);
          setCurrentCampaign(fetchedCampaignData?.data ?? '');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleTimeDropdown = () => {
    setIsTimeSelectorOpen((prevState) => !prevState);
  };
  const toggleTimeZoneDropdown = () => {
    setIsTimeZoneDropdownOpen((prevState) => !prevState);
  };

  const togglePlatformDropdown = () => {
    setIsPlatformDropdownOpen((prevState) => !prevState);
  };

  const toggleToneDropdown = () => {
    setIsToneDropdownOpen((prevState) => !prevState);
  };

  const openDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(true);
  };

  const closeDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(false);
  };

  const handleChangeTimeZone = (value: string) => {
    const extractedTimeZone = extractInfoInParentheses(value);
    setFormikValues((prevState) => ({
      ...prevState,
      timezone: extractedTimeZone,
    }));
    setIsTimeZoneDropdownOpen(false);
  };

  const handleChangeLaunchTime = (value: string) => {
    setFormikValues((prevState) => ({
      ...prevState,
      campaignLaunch: value,
    }));
    setIsTimeSelectorOpen(false);
  };

  const handleChangeTone = (value: string) => {
    setFormikValues((prevState) => ({
      ...prevState,
      toneSelection: value,
    }));
  };

  const handleChangePlatform = (value: string) => {
    setFormikValues((prevState) => ({
      ...prevState,
      platforms: [value], // replace the entire platforms array with the new value
    }));
  };

  const handleSetChosenAgentId = (selectedAgents: string) => {
    setChosenAgentId(selectedAgents);
  };

  const validationSchema = Yup.object().shape({
    name: campaignNameValidationSchema,
    link: campaignLinkValidationSchema,
    platforms: platformValidationSchema,
  });

  const fetchUserAgents = async () => {
    try {
      const { data } = await getAgents();
      if (data) {
        const sortedData = data.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
        setAgentsData(sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectedAgentNames = agentsData
    .filter((agent) => chosenAgentId?.includes(agent.id))
    .map((agent) => agent.name)
    .join(', '); // Join agent names with commas

  useEffect(() => {
    fetchUserAgents();
  }, []);

  return (
    <>
      <Formik
        initialValues={formikValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values, isValid, setFieldValue }) => (
          <Form className="w-100 mt-1_5rem">
            <div className="d-flex-start-between-row w-100">
              <div className="d-flex-start-start w-100 mr-1rem mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label htmlFor="name" className="campaign-label-input">
                    Name of Campaign *
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="First Property Viewing "
                    required
                    className="campaign-input w-100"
                  />
                  {errors?.name && (
                    <div className="form-error">{errors?.name}</div>
                  )}
                </div>
                <div
                  className="d-flex-start-start w-100 mb-1rem p-relative"
                  onClick={openDateRangeSelector}
                >
                  <label htmlFor="duration" className="campaign-label-input">
                    Duration
                  </label>
                  <div className="d-flex-row campaign-input w-100 pointer">
                    <CalendarBlackIcon />
                    <span className="pl-0_5rem pointer">
                      {`(${formikValues?.startDate}) - (${formikValues?.endDate})`}
                    </span>
                  </div>
                  <ChevronDownIcon
                    className={`${isDateRangeSelectorOpen && 't-rotate-180'} p-absolute right-0_625rem top-2_8rem z1`}
                  />
                  {isDateRangeSelectorOpen && (
                    <DateRangeSelector
                      closeDateRangeSelector={closeDateRangeSelector}
                      formikValues={formikValues}
                      setFormikValues={setFormikValues}
                    />
                  )}
                </div>
                <div className="d-flex-start-start-row w-100 mb-1rem">
                  <div className="d-flex-start-start mr-1rem p-relative">
                    <div className="d-flex-row">
                      <label
                        htmlFor="campaignLaunch"
                        className="campaign-label-input"
                      >
                        Campaign Launch
                      </label>
                      <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
                        <InfoIcon />
                      </div>
                    </div>
                    <div
                      className="d-flex-row campaign-input w-9rem pointer"
                      onClick={toggleTimeDropdown}
                    >
                      <span>
                        {getFormatTimeWithoutPeriod(
                          formikValues?.campaignLaunch
                        )}
                      </span>
                    </div>
                    <div className="d-flex-row p-absolute right-0_625rem top-2_5rem pointer z1">
                      <span className="text-input-placeholder pr-0_25rem">
                        {isAM(formikValues?.campaignLaunch) ? 'AM' : 'PM'}
                      </span>
                      <ChevronDownIcon
                        className={`${isTimeSelectorOpen && 't-rotate-180'} `}
                      />
                    </div>
                    {isTimeSelectorOpen && (
                      <div className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white">
                        {campaignLaunchTimeConfig.map((option, index) => (
                          <div
                            key={index}
                            className={`${option === formikValues?.campaignLaunch && 'bg-badge'} d-flex-center-start h-2_375rem bb-1 hovered`}
                            onClick={() => handleChangeLaunchTime(option)}
                          >
                            <span className="pl-0_5rem f-roboto-m-primary fs-0_875rem">
                              {convertTimeTo12HourFormat(option)}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="d-flex-start-start p-relative">
                    <div className="d-flex-row">
                      <label
                        htmlFor="timezone"
                        className="campaign-label-input"
                      >
                        Timezone
                      </label>
                      <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
                        <InfoIcon />
                      </div>
                    </div>
                    <div
                      className="d-flex-row campaign-input w-9rem pointer"
                      onClick={toggleTimeZoneDropdown}
                    >
                      <span>{formikValues.timezone}</span>
                    </div>
                    <ChevronDownIcon
                      className={`${isTimeZoneDropdownOpen && 't-rotate-180'} p-absolute right-0_625rem top-2_8rem z1`}
                    />
                    {isTimeZoneDropdownOpen && (
                      <div className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white">
                        {timeZoneOptions.map((option, index) => (
                          <div
                            key={index}
                            className={`${extractInfoInParentheses(option) === formikValues?.timezone && 'bg-badge'} d-flex-center-start h-2_375rem bb-1 hovered`}
                            onClick={() => handleChangeTimeZone(option)}
                          >
                            <span className="pl-0_5rem f-roboto-m-primary fs-0_875rem">
                              {option}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="d-flex-start-start w-100 p-relative"
                  onClick={handleShowAgentSelectionModal}
                >
                  <div className="d-flex-row">
                    <label htmlFor="name" className="campaign-label-input">
                      Agent Selection
                    </label>
                    <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
                      <InfoIcon />
                    </div>
                  </div>
                  <div className="d-flex-row campaign-input w-100 pointer">
                    <span>{selectedAgentNames || 'No agents selected'}</span>
                  </div>
                  <ChevronDownIcon
                    className={`${isToneDropdownOpen && 't-rotate-180'} p-absolute right-0_625rem top-2_8rem z1`}
                  />
                </div>
              </div>
              <div className="d-flex-start-start w-100 mr-1rem mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label htmlFor="link" className="campaign-label-input">
                    Link to be listed in conversations *
                  </label>
                  <Field
                    type="text"
                    id="link"
                    name="link"
                    placeholder="https:www.calendly.com/astreaux"
                    required
                    className="campaign-input w-100"
                  />
                  {errors?.link && (
                    <div className="form-error">{errors?.link}</div>
                  )}
                </div>
                <div className="d-flex-start-start w-100 mb-1rem">
                  <label
                    htmlFor="goalAndFocus"
                    className="campaign-label-input"
                  >
                    Goal and Focus of Conversation
                  </label>
                  <Field
                    type="text"
                    id="goalAndFocus"
                    name="goalAndFocus"
                    placeholder="Book an appointment to an open house"
                    className="campaign-input w-100"
                  />
                </div>
                <div
                  className="d-flex-start-start w-100 p-relative mb-1rem"
                  onClick={toggleToneDropdown}
                >
                  <div className="d-flex-row">
                    <label
                      htmlFor="toneSelection"
                      className="campaign-label-input"
                    >
                      Tone Selection
                    </label>
                    <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
                      <InfoIcon />
                    </div>
                  </div>
                  <div className="d-flex-row campaign-input w-100 pointer">
                    <span>{formikValues?.toneSelection}</span>
                  </div>
                  <ChevronDownIcon
                    className={`${isToneDropdownOpen && 't-rotate-180'} p-absolute right-0_625rem top-2_8rem z1`}
                  />
                  {isToneDropdownOpen && (
                    <div className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white">
                      {CampaignToneDropdownConfig.map((option, index) => (
                        <div
                          key={index}
                          className={`${option === formikValues?.toneSelection && 'bg-badge'} d-flex-center-start h-2_375rem bb-1 hovered`}
                          onClick={() => handleChangeTone(option)}
                        >
                          <span className="pl-0_5rem f-roboto-m-primary fs-0_875rem">
                            {option}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div
                  className="d-flex-start-start w-100 p-relative "
                  onClick={handleShowAdCampaignSelectionModal}
                >
                  <div className="d-flex-row">
                    <label
                      htmlFor="adCampaignSelection"
                      className="campaign-label-input"
                    >
                      Ad Campaign Selection
                    </label>
                    <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
                      <InfoIcon />
                    </div>
                  </div>
                  <div className="d-flex-row campaign-input w-100 pointer">
                    <span></span>
                  </div>
                  <ChevronDownIcon
                    className={`${isToneDropdownOpen && 't-rotate-180'} p-absolute right-0_625rem top-2_8rem z1`}
                  />
                </div>
              </div>
              <div className="d-flex-start-start w-100 mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem">
                  <label
                    htmlFor="backgroundInformation"
                    className="campaign-label-input"
                  >
                    Background Information
                  </label>
                  <Field
                    as="textarea"
                    id="backgroundInformation"
                    name="backgroundInformation"
                    className="campaign-textarea w-100"
                    placeholder="The addresss is 123 Cloverfield lane. The open house starts at 5pm and ends at 7pm. Visitors must show up on time."
                  />
                </div>
                <div
                  className="d-flex-start-start w-100 p-relative mb-1rem"
                  onClick={togglePlatformDropdown}
                >
                  <div className="d-flex-row">
                    <label
                      htmlFor="toneSelection"
                      className="campaign-label-input"
                    >
                      Ad Platform Selection
                    </label>
                    <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
                      <InfoIcon />
                    </div>
                  </div>
                  <div className="d-flex-row campaign-input w-100 pointer">
                    <span>{formikValues?.platforms[0]}</span>
                  </div>
                  <ChevronDownIcon
                    className={`${isPlatformDropdownOpen && 't-rotate-180'} p-absolute right-0_625rem top-2_8rem z1`}
                  />
                  {errors?.platforms && (
                    <div className="form-error">{errors?.platforms}</div>
                  )}
                  {isPlatformDropdownOpen && (
                    <div className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white">
                      {CampaignPlatformDropdownConfig.map((option, index) => (
                        <div
                          key={index}
                          className={`${option === formikValues?.platforms[0] && 'bg-badge'} d-flex-center-start h-2_375rem bb-1 hovered`}
                          onClick={() => {
                            if (
                              option === 'SMS' ||
                              option === 'Facebook' ||
                              option === 'Instagram'
                            ) {
                              handleChangePlatform(option); // Call your custom function
                              setFieldValue('platforms', [option]); // Update Formik values
                            }
                          }} // Only trigger if option is "SMS"
                        >
                          <span
                            className={`${option === 'SMS' || option === 'Facebook' || option === 'Instagram' ? 'f-roboto-m-primary' : 'c-secondary'}  pl-0_5rem f-roboto-m-primary fs-0_875rem`}
                          >
                            {option}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn-header-primary"
                  disabled={!isValid}
                >
                  <span>Apply</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showUpdateModal && (
        <InfoModal
          title="Campaign updated successfully!"
          onClose={handleCloseUpdateModal}
        />
      )}
      {isAgentSelectionModalOpen && (
        <AgentSelectionModal
          onClose={handleCloseAgentSelectionModal}
          onConfirm={handleSetChosenAgentId}
          chosenAgentId={chosenAgentId ?? ''}
          agentsData={agentsData}
        />
      )}
      {isAdCampaignSelectionModalOpen && (
        <AdCampaignSelectionModal
          onClose={handleCloseAdCampaignSelectionModal}
          onConfirm={handleCloseAdCampaignSelectionModal}
        />
      )}
    </>
  );
};

export default CampaignSettings;
