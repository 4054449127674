import React from 'react';
import { ArrowUpRightGreenIcon, InfoIcon } from '../../../assets';
import { DashboardLeadsChartsProps } from './dashboardModels';
import { formatStatisticValue } from '../../../helpers/displayDataHelpers';

const DashboardLeadsCharts: React.FC<DashboardLeadsChartsProps> = ({
  dashboardStatisticsData,
}) => {
  return (
    <div className="d-flex-start-between h-100 w-20 p-1_125rem-1_625rem br-1-secondary">
      <div className="d-flex-start-center w-100 h-100">
        <div className="d-flex-row pt-2_25rem">
          <h4 className="ff-inter c-title fs-2rem  ls-_-0_0016rem fw-600">
            Leads
          </h4>
          <div className="d-flex-center icon-hovered pt-1rem ml-0_25rem">
            <InfoIcon />
          </div>
        </div>
        <h1 className="ff-montserrat fw-600 c-black fs-4_375rem lh-5_25rem pt-2rem">
          {formatStatisticValue(dashboardStatisticsData?.totalLeads ?? 0)}
        </h1>
      </div>
      {/*<div className="d-flex-row">
        <div className="d-flex-center">
          <ArrowUpRightGreenIcon />
        </div>
        <span className="ff-inter c-52a86e fw-600 fs-0_75rem ls-_-0_002rem lh-1_125rem">
          +4.11%
        </span>
        <span className="ff-inter c-5f5f61 fs-0_75rem ls-_-0_002rem lh-1_125rem pl-0_25rem">
          vs last 30 days
        </span>
      </div>*/}
    </div>
  );
};

export default DashboardLeadsCharts;
