import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { ConversationChartsProps } from './conversationModels';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const getLabels = (period: 'weekly' | 'monthly' | 'yearly') => {
  if (period === 'weekly') {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  } else if (period === 'monthly') {
    const daysInMonth = moment().daysInMonth();
    return Array.from({ length: daysInMonth }, (_, i) => `Day ${i + 1}`);
  } else if (period === 'yearly') {
    return [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
  }
  return [];
};

const mapStatisticsToChartData = (
  statisticsData: { date: string; conversationalLeads: number }[],
  period: 'weekly' | 'monthly' | 'yearly'
) => {
  if (period === 'weekly') {
    const weekStart = moment().startOf('isoWeek');
    const weekData = Array(7).fill(0);

    statisticsData.forEach(({ date, conversationalLeads }) => {
      const dayIndex = moment(date).diff(weekStart, 'days');
      if (dayIndex >= 0 && dayIndex < 7) {
        weekData[dayIndex] = conversationalLeads;
      }
    });

    return weekData;
  } else if (period === 'monthly') {
    const daysInMonth = moment().daysInMonth();
    const monthData = Array(daysInMonth).fill(0);

    statisticsData.forEach(({ date, conversationalLeads }) => {
      const dayIndex = moment(date).date() - 1;
      if (dayIndex >= 0 && dayIndex < daysInMonth) {
        monthData[dayIndex] = conversationalLeads;
      }
    });

    return monthData;
  } else if (period === 'yearly') {
    const yearData = Array(12).fill(0);

    statisticsData.forEach(({ date, conversationalLeads }) => {
      const monthIndex = moment(date).month();
      yearData[monthIndex] = conversationalLeads;
    });

    return yearData;
  }
  return [];
};

const ConversationCharts: React.FC<ConversationChartsProps> = ({
  activePeriod,
  setActivePeriod,
  statisticsData,
}) => {
  const [chartData, setChartData] = useState({
    labels: getLabels(activePeriod),
    datasets: [
      {
        label: 'Conversational Leads',
        data: mapStatisticsToChartData(statisticsData, activePeriod),
        backgroundColor: '#47A3FF',
      },
    ],
  });

  useEffect(() => {
    setChartData({
      labels: getLabels(activePeriod),
      datasets: [
        {
          label: 'Conversational Leads',
          data: mapStatisticsToChartData(statisticsData, activePeriod),
          backgroundColor: '#47A3FF',
        },
      ],
    });
  }, [activePeriod, statisticsData]);

  const handlePeriodChange = (period: 'weekly' | 'monthly' | 'yearly') => {
    setActivePeriod(period);
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="d-flex-start-start w-100 bg-white p-1rem-1_25rem b-radius-1_25rem mb-2rem">
      <div className="d-flex-row-space-between w-100 pb-1_5rem">
        <h4 className="h4-primary">All Conversational Leads</h4>
        <div className="d-flex-row h-1_875rem b-1 b-radius-0_5rem p-0-0_0625rem">
          <button
            className={`btn-conversation-chart ${activePeriod === 'weekly' ? 'active' : ''}`}
            onClick={() => handlePeriodChange('weekly')}
          >
            Weekly
          </button>
          <button
            className={`btn-conversation-chart ${activePeriod === 'monthly' ? 'active' : ''}`}
            onClick={() => handlePeriodChange('monthly')}
          >
            Monthly
          </button>
          <button
            className={`btn-conversation-chart ${activePeriod === 'yearly' ? 'active' : ''}`}
            onClick={() => handlePeriodChange('yearly')}
          >
            Yearly
          </button>
        </div>
      </div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default ConversationCharts;
