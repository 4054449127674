import React, { useRef, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

import {
  ArrowDownRightRedIcon,
  ArrowUpRightGreenIcon,
  InfoIcon,
} from '../../../assets';
import { ConversationsChartProps } from './dashboardModels';
import {
  calculateStatisticsPercentage,
  formatStatisticValue,
} from '../../../helpers/displayDataHelpers';

Chart.register(ArcElement, Tooltip, Legend);

const ConversationsChart: React.FC<ConversationsChartProps> = ({
  dashboardStatisticsData,
}) => {
  const chartRef = useRef<any>(null);

  const value = dashboardStatisticsData?.conversation?.prevValue ?? 0; // Example value, you can make this dynamic
  const remaining =
    dashboardStatisticsData?.conversation?.value === 0
      ? 1
      : dashboardStatisticsData?.conversation?.value ?? 0;

  const data = {
    labels: ['Views'],
    datasets: [
      {
        data: [value, remaining],
        backgroundColor: ['#8AEFF5', '#0AB9F0'], // Placeholder, gradient will be applied later
        hoverOffset: 0,
        spacing: 0,
        cutout: '80%', // Adjust the inner radius for donut effect
        borderRadius: 50,
      },
    ],
  };

  const options = {
    rotation: 180, // Start the chart from the bottom
    circumference: 360, // Full circle
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  // Create dynamic gradient background for the chart
  useEffect(() => {
    const chartInstance = chartRef.current;

    if (chartInstance) {
      const ctx = chartInstance.ctx;

      // Calculate the gradient based on the value
      const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);

      // Dynamic color stops based on the value (darkest for high value, lightest for low value)
      const startColor = '#0AB9F0';
      const endColor = '#8AEFF5';
      const gradientValue = Math.min(Math.max(value / 100, 0), 1);

      // Apply gradient based on the value
      gradient.addColorStop(0, startColor);
      gradient.addColorStop(gradientValue, endColor); // Dynamic gradient stop

      chartInstance.data.datasets[0].backgroundColor = [gradient, '#14F1E40F']; // Gradient with transparent background
      chartInstance.update();
    }
  }, [value]);

  return (
    <div className="d-flex-center-between h-100 w-20 p-1_125rem-1_625rem br-1-secondary">
      <div className="d-flex-center p-relative w-9rem h-9rem">
        <Doughnut data={data} options={options} ref={chartRef} />
        <div className="d-flex-center p-absolute left-0 top-0 w-9rem h-9rem">
          <span className="ff-montserrat c-4dd5f3 fs-1_75rem lh-2_125rem fw-600">
            {formatStatisticValue(
              dashboardStatisticsData?.conversation?.value ?? 0
            )}
          </span>
          <span className="ff-montserrat c-clicks fs-0_75rem">sent</span>
        </div>
      </div>
      <div className="d-flex-center w-100">
        <div className="d-flex-row pb-1_375rem">
          <h4 className="ff-inter c-title fs-1_125rem ls-_-0_0016rem fw-600">
            Conversations Sent
          </h4>
          <div className="d-flex-center icon-hovered ml-0_25rem">
            <InfoIcon />
          </div>
        </div>
        {(dashboardStatisticsData?.conversation?.value ?? 0) >=
        (dashboardStatisticsData?.conversation?.prevValue ?? 0) ? (
          <div className="d-flex-row">
            <div className="d-flex-center">
              <ArrowUpRightGreenIcon />
            </div>
            <span className="ff-inter c-52a86e fw-600 fs-0_75rem ls-_-0_002rem lh-1_125rem">
              +
              {calculateStatisticsPercentage(
                dashboardStatisticsData?.conversation?.value ?? 0,
                dashboardStatisticsData?.conversation?.prevValue ?? 0
              )}
            </span>
            <span className="ff-inter c-5f5f61 fs-0_75rem ls-_-0_002rem lh-1_125rem pl-0_25rem">
              vs last 30 days
            </span>
          </div>
        ) : (
          <div className="d-flex-row">
            <div className="d-flex-center">
              <ArrowDownRightRedIcon />
            </div>
            <span className="ff-inter c-e73f3f fw-600 fs-0_75rem ls-_-0_002rem lh-1_125rem">
              {calculateStatisticsPercentage(
                dashboardStatisticsData?.conversation?.value ?? 0,
                dashboardStatisticsData?.conversation?.prevValue ?? 0
              )}
            </span>
            <span className="ff-inter c-5f5f61 fs-0_75rem ls-_-0_002rem lh-1_125rem pl-0_25rem">
              vs last 30 days
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationsChart;
