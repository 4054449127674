import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAppData } from '../../context/AppContext';
import {
  AnalyticsIcon,
  AssistantIcon,
  CalendarIcon,
  CampaignsIcon,
  ConversationsIcon,
  DashboardIcon,
  IntegrationsIcon,
  LeadsIcon,
  LogoSmallPNG,
  LogoWithTitlePNG,
  SettingsIcon,
  SupportIcon,
} from '../../assets';

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sidebarState, setSidebarState } = useAppData();
  const [activeItem, setActiveItem] = useState<string>('dashboard');

  const sidebarVisible = sidebarState === 'visible';
  const sidebarHidden = sidebarState === 'hidden';

  const handleItemClick = (itemName: string) => {
    setActiveItem(itemName);
    navigate(`/${itemName}`);
  };

  const handleMouseEnter = () => {
    setSidebarState('visible');
  };

  const handleMouseLeave = () => {
    setSidebarState('minimized');
  };

  useEffect(() => {
    const pathname = location.pathname.slice(1);
    setActiveItem(pathname);
  }, [location.pathname]);

  return (
    <>
      {sidebarHidden ? null : (
        <div
          className={`p-fixed d-flex-start-start ${sidebarVisible ? 'w-15_625rem' : 'w-4rem'} h-100vh  top-0 left-0 bg-white br-1 z2`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Logo and Company Title */}
          <div
            className={`${sidebarVisible ? 'd-flex-row bb-1 pb-0_5rem' : 'd-flex-center'} h-4_375rem min-h-4_375rem w-100 pointer`}
          >
            {sidebarVisible ? (
              <img alt="" src={LogoWithTitlePNG} className="h-2_75rem" />
            ) : (
              <img alt="" src={LogoSmallPNG} className="w-2_75rem h-2_75rem" />
            )}
          </div>
          <div
            className={`${sidebarVisible ? 'd-flex-start-start' : 'd-flex-start-center'} w-100 scroll-auto`}
          >
            {/* Main Title */}
            {sidebarVisible && (
              <div className="pt-2_125rem pb-0_375rem pl-1_25rem">
                <span className="submenu-title">Main</span>
              </div>
            )}
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'p-0_938rem-0'} d-flex-row sidebar-item-container ${activeItem === 'dashboard' ? 'active' : ''}`}
              onClick={() => handleItemClick('dashboard')}
            >
              {activeItem === 'dashboard' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <DashboardIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">Dashboard</span>
              )}
            </div>
            <div className="d-flex-column w-100">
              <div
                className={`p-1_312rem-0 w-100 pl-1_25rem d-flex-row sidebar-item-container ${activeItem === 'leads' ? 'active' : ''}`}
                onClick={() => handleItemClick('leads')}
              >
                {activeItem === 'leads' && sidebarVisible && (
                  <div className="sidebar-item-active"></div>
                )}
                <LeadsIcon className="sidebar-icon" />
                {sidebarVisible && (
                  <div className="d-flex-row-space-between w-100 pr-0_625rem">
                    <span className="pl-1_25rem sidebar-item-title">Leads</span>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'p-0_938rem-0'} d-flex-row sidebar-item-container ${activeItem === 'email-assistant' ? 'active' : ''}`}
              onClick={() => handleItemClick('email-assistant')}
            >
              {activeItem === 'email-assistant' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <AssistantIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">
                  Email Assistant
                </span>
              )}
            </div>
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'p-0_938rem-0'} d-flex-row sidebar-item-container ${activeItem === 'campaigns' ? 'active' : ''}`}
              onClick={() => handleItemClick('campaigns')}
            >
              {activeItem === 'campaigns' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <CampaignsIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">Campaigns</span>
              )}
            </div>
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'p-0_938rem-0'} d-flex-row sidebar-item-container ${activeItem === 'conversations' ? 'active' : ''}`}
              onClick={() => handleItemClick('conversations')}
            >
              {activeItem === 'conversations' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <ConversationsIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">
                  Conversations
                </span>
              )}
            </div>
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'p-0_938rem-0'} d-flex-row sidebar-item-container ${activeItem === 'analytics' ? 'active' : ''}`}
              onClick={() => handleItemClick('analytics')}
            >
              {activeItem === 'analytics' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <AnalyticsIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">Analytics</span>
              )}
            </div>
            {/*Other title */}
            {sidebarVisible && (
              <div className="pt-1_5rem pb-0_375rem pl-1_25rem">
                <span className="submenu-title">Other</span>
              </div>
            )}
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'pt-2_688rem pb-0_938rem'} d-flex-row sidebar-item-container ${activeItem === 'calendar' ? 'active' : ''}`}
              onClick={() => handleItemClick('calendar')}
            >
              {activeItem === 'calendar' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <CalendarIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">Calendar</span>
              )}
            </div>
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'p-0_938rem-0'} d-flex-row sidebar-item-container ${activeItem === 'settings' ? 'active' : ''}`}
              onClick={() => handleItemClick('settings')}
            >
              {activeItem === 'settings' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <SettingsIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">Settings</span>
              )}
            </div>
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'p-0_938rem-0'} d-flex-row sidebar-item-container ${activeItem === 'integrations' ? 'active' : ''}`}
              onClick={() => handleItemClick('integrations')}
            >
              {activeItem === 'integrations' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <IntegrationsIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">
                  Integrations
                </span>
              )}
            </div>
            <div
              className={`${sidebarVisible ? 'p-1_312rem-0 w-100 pl-1_25rem ' : 'p-0_938rem-0'} d-flex-row sidebar-item-container ${activeItem === 'support' ? 'active' : ''}`}
              onClick={() => handleItemClick('support')}
            >
              {activeItem === 'support' && sidebarVisible && (
                <div className="sidebar-item-active"></div>
              )}
              <SupportIcon className="sidebar-icon" />
              {sidebarVisible && (
                <span className="pl-1_25rem sidebar-item-title">Support</span>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
