import React from 'react';
import {
  AstreauxActivityStats,
  MetaActivityStats,
  SMSActivityStats,
} from './ActivityStats';
import { LeadActivityStatsProps } from './analyticsModels';

const LeadActivityStats: React.FC<LeadActivityStatsProps> = ({
  analyticsData,
}) => {
  return (
    <div className="d-flex-start-between-row w-100 mt-1_75rem mb-2rem">
      <MetaActivityStats
        analyticsServicesMetaData={analyticsData?.services?.meta ?? null}
      />
      <SMSActivityStats
        analyticsServicesSMSData={analyticsData?.services?.sms ?? null}
      />
      <AstreauxActivityStats
        analyticsServicesAstreauxData={
          analyticsData?.services?.astreaux ?? null
        }
      />
    </div>
  );
};

export default LeadActivityStats;
