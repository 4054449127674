import React, { useState, useEffect } from 'react';
import { FacebookAdCampaignsProps } from '../../../../core/types/_models';
import { getFacebookAdCampaigns } from '../../../../core/requests/requests';
import { useAppData } from '../../../../context/AppContext';
import { ConnectFacebookAdCampaignsProps } from '../startCampaignModels';

const ConnectFacebookAdCampaigns: React.FC<ConnectFacebookAdCampaignsProps> = ({
  chosenFacebookAdAccountId,
  selectedFacebookAdCampaignId,
  setSelectedFacebookAdCampaignId,
}) => {
  const { currentCampaign } = useAppData();
  const [facebookAdCampaignsData, setFacebookAdCampaignsData] = useState<
    FacebookAdCampaignsProps[]
  >([]);

  useEffect(() => {
    const fetchFacebookAdCampaigns = async () => {
      try {
        if (chosenFacebookAdAccountId && currentCampaign?.id) {
          const response = await getFacebookAdCampaigns(
            chosenFacebookAdAccountId,
            currentCampaign?.id
          );
          if (response?.data) {
            setFacebookAdCampaignsData(response.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchFacebookAdCampaigns();
  }, [chosenFacebookAdAccountId, currentCampaign?.id]);

  const handleFacebookAdCampaignIdChange = (facebookAdCampaignId: string) => {
    setSelectedFacebookAdCampaignId(
      selectedFacebookAdCampaignId === facebookAdCampaignId
        ? null
        : facebookAdCampaignId
    );
  };

  return (
    <div className="d-flex-start-start w-100 p-1rem">
      <div className="d-flex-row w-100 pb-1rem bb-1-l-gray">
        <span className="ff-poppins fw-500 fs-0_875rem c-regular">
          Select Facebook Ad Campaign Id:
        </span>
      </div>
      {facebookAdCampaignsData?.length > 0 ? (
        facebookAdCampaignsData
          .filter(
            (facebookAdCampaign) =>
              !selectedFacebookAdCampaignId ||
              selectedFacebookAdCampaignId === facebookAdCampaign.id
          )
          .map((facebookAdCampaign) => (
            <div
              className={`${
                selectedFacebookAdCampaignId !== null &&
                selectedFacebookAdCampaignId !== facebookAdCampaign.id
                  ? 'c-9ca3af'
                  : ''
              } d-flex-row w-100 p-1rem bb-1 hovered`}
              key={facebookAdCampaign.id}
              onClick={() =>
                handleFacebookAdCampaignIdChange(facebookAdCampaign.id)
              }
            >
              <div className="d-flex-center mr-1rem">
                <input
                  type="checkbox"
                  className="conversation-filter-checkbox"
                  checked={
                    selectedFacebookAdCampaignId === facebookAdCampaign.id
                  }
                />
              </div>
              <span className="mr-1rem ff-inter c-111928 fs-0_875rem">
                {facebookAdCampaign.name}
              </span>
            </div>
          ))
      ) : (
        <div className="d-flex-center w-100 p-1rem">
          <span className="ff-inter c-111928 fs-0_875rem">
            No Facebook Ad Campaigns found
          </span>
        </div>
      )}
    </div>
  );
};

export default ConnectFacebookAdCampaigns;
