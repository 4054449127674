import React, { useState, useEffect } from 'react';
import { FacebookAdIdProps } from '../../../../core/types/_models';
import { ConnectFacebookAdIdProps } from '../startCampaignModels';
import { getFacebookAdId } from '../../../../core/requests/requests';
import { useAppData } from '../../../../context/AppContext';

const ConnectFacebookAdId: React.FC<ConnectFacebookAdIdProps> = ({
  adCampaignId,
  selectedFacebookAdId,
  setSelectedFacebookAdId,
}) => {
  const { currentCampaign } = useAppData();
  const [facebookAdIdData, setFacebookAdIdData] = useState<FacebookAdIdProps[]>(
    []
  );

  useEffect(() => {
    const fetchFacebookAdId = async () => {
      try {
        if (adCampaignId && currentCampaign?.id) {
          const { data } = await getFacebookAdId(
            adCampaignId,
            currentCampaign?.id
          );
          setFacebookAdIdData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchFacebookAdId();
  }, [adCampaignId, currentCampaign?.id]);

  const handleFacebookAdIdChange = (facebookAdId: string) => {
    setSelectedFacebookAdId(
      selectedFacebookAdId === facebookAdId ? null : facebookAdId
    );
  };

  return (
    <div className="d-flex-start-start w-100 p-1rem">
      <div className="d-flex-row w-100 pb-1rem bb-1-l-gray">
        <span className="ff-poppins fw-500 fs-0_875rem c-regular">
          Select Facebook Ad Id:
        </span>
      </div>
      {facebookAdIdData?.length > 0 ? (
        <div className="d-flex-row-start-start w-100">
          {facebookAdIdData
            .filter(
              (facebookAdId) =>
                !selectedFacebookAdId ||
                selectedFacebookAdId === facebookAdId.id
            )
            .map((facebookAdId) => (
              <div
                className={`${
                  selectedFacebookAdId !== null &&
                  selectedFacebookAdId !== facebookAdId.id
                    ? 'c-9ca3af'
                    : ''
                } d-flex-row w-100 p-1rem bb-1 hovered`}
                key={facebookAdId.id}
                onClick={() => handleFacebookAdIdChange(facebookAdId.id)}
              >
                <div className="d-flex-center mr-1rem">
                  <input
                    type="checkbox"
                    className="conversation-filter-checkbox"
                    checked={selectedFacebookAdId === facebookAdId.id}
                  />
                </div>
                <span className="mr-1rem ff-inter c-111928 fs-0_875rem">
                  {facebookAdId.name}
                </span>
              </div>
            ))}
        </div>
      ) : (
        <div className="d-flex-center w-100 p-1rem">
          <span className="ff-inter c-111928 fs-0_875rem">
            No Facebook Ad Id found
          </span>
        </div>
      )}
    </div>
  );
};

export default ConnectFacebookAdId;
