import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

import {
  CheckboxActiveIcon,
  ShowPasswordIcon,
  HidePasswordIcon,
  RectangleDownIcon,
  LoginPNG,
} from '../../assets';
import { countryPhoneCodes } from '../../config';
import {
  RegistrationPayloadProps,
  SelectedCountryProps,
} from '../../core/types/_models';
import { registerUser, getUser } from '../../core/requests/requests';
import { useAuth } from '../../context/AuthContext';
import {
  agreeToPolicyValidationSchema,
  confirmPasswordValidationSchema,
  emailValidationSchema,
  firstNameValidationSchema,
  lastNameValidationSchema,
  passwordValidationSchema,
  phoneNumberValidationSchema,
} from './components/ValidationSchemaItems';

const Registration: React.FC = () => {
  const navigate = useNavigate();
  const { setAuthLogin, setCurrentUser } = useAuth();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [inputCountryValue, setInputCountryValue] = useState<string>('');
  const [countryDropdownOpen, setCountryDropdownOpen] =
    useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] =
    useState<SelectedCountryProps | null>(null);
  const [filteredCountries, setFilteredCountries] =
    useState<SelectedCountryProps[]>(countryPhoneCodes);
  const [focusedFields, setFocusedFields] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    country: false,
    email: false,
    password: false,
    confirmPassword: false,
  });

  const [registrationPayload, setRegistrationPayload] =
    useState<RegistrationPayloadProps>({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      country: '',
      email: '',
      password: '',
      confirmPassword: '',
      agreeToPolicy: false,
    });

  const handleFieldFocus = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: true,
    }));

    if (fieldName === 'country') {
      setCountryDropdownOpen(true);
    }
  };

  const handleFieldBlur = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: false,
    }));
  };

  const filterCountries = (value: string) => {
    const filtered = countryPhoneCodes.filter(
      (country) =>
        country.country.toLowerCase().includes(value.toLowerCase()) ||
        country.code.includes(value)
    );
    setFilteredCountries(filtered);
  };

  const toggleCountryDropdown = () => {
    setCountryDropdownOpen((countryDropdownOpen) => !countryDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setCountryDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const matchedCountry = countryPhoneCodes.find(
      (countryObj) => countryObj.country === inputCountryValue
    );
    if (matchedCountry) {
      setSelectedCountry(matchedCountry);
      setRegistrationPayload((prevValues) => ({
        ...prevValues,
        country: inputCountryValue,
      }));
    } else {
      setSelectedCountry(null);
    }
  }, [inputCountryValue]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  const validationSchema = Yup.object().shape({
    firstName: firstNameValidationSchema,
    lastName: lastNameValidationSchema,
    phoneNumber: phoneNumberValidationSchema,
    email: emailValidationSchema,
    password: passwordValidationSchema,
    confirmPassword: confirmPasswordValidationSchema,
    agreeToPolicy: agreeToPolicyValidationSchema,
  });

  const handleSubmit = async (values: RegistrationPayloadProps) => {
    let formattedPhoneNumber = values.phoneNumber;
    if (selectedCountry && selectedCountry.code) {
      formattedPhoneNumber = `+${selectedCountry.code}${values.phoneNumber}`;
    }

    const modifiedValues = {
      ...values,
      phoneNumber: formattedPhoneNumber,
      country: inputCountryValue,
      email: values.email,
    };

    if (modifiedValues) {
      try {
        const { data } = await registerUser(modifiedValues);
        if (data) {
          setAuthLogin(data);
          const response = await getUser(data.userId, data.accessToken);
          setCurrentUser(response.data);
          navigate('/');
        }
      } catch {
        console.log('Registration failed');
      }
    }
  };

  return (
    <div className="d-flex-center-start-row size-max">
      <div className="d-flex-center h-100 w-50 bg-fffefe z2 p-relative">
        <div className="d-flex-start-start w-80 max-w-40rem">
          <h1 className="auth-title pl-10rem">Sign Up</h1>
          <div className="d-flex-center-start w-100 bg-white auth-form">
            <Formik
              initialValues={registrationPayload}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, values, setFieldValue, isValid }) => (
                <Form className="w-100">
                  <div className="d-flex-row form-row mb-0_625rem w-100 mb-1_25rem">
                    <div className="form-item w-calc-50_minus_0_625rem mr-1_25rem ">
                      <Field
                        type="text"
                        name="firstName"
                        className={`form-control ${errors.firstName ? 'error-border' : ''} `}
                        placeholder="First Name"
                        onFocus={() => handleFieldFocus('firstName')}
                        onBlur={() => handleFieldBlur('firstName')}
                      />
                      {errors.firstName && (
                        <div className="form-error">{errors.firstName}</div>
                      )}
                      {focusedFields.firstName && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${
                              errors.firstName ? 'error-title' : 'correct-title'
                            }`}
                          >
                            First Name
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="form-item w-calc-50_minus_0_625rem ">
                      <Field
                        type="text"
                        name="lastName"
                        className={`form-control ${errors.lastName ? 'error-border' : ''} `}
                        placeholder="Last Name"
                        onFocus={() => handleFieldFocus('lastName')}
                        onBlur={() => handleFieldBlur('lastName')}
                      />
                      {errors.lastName && (
                        <div className="form-error">{errors.lastName}</div>
                      )}
                      {focusedFields.lastName && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${
                              errors.lastName ? 'error-title' : 'correct-title'
                            }`}
                          >
                            Last Name
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex-row form-row mb-1_25rem w-100">
                    <div className="form-item w-3_125rem mr-0_625rem">
                      <div
                        className={`phone-code ${selectedCountry?.country && 'phone-code-active'}`}
                      >
                        {selectedCountry ? `+${selectedCountry?.code}` : null}
                      </div>
                    </div>
                    <div className="form-item w-calc-50_minus_4_375rem mr-1_25rem">
                      <Field
                        type="text"
                        name="phoneNumber"
                        className={`form-control ${errors.phoneNumber ? 'error-border' : ''} `}
                        placeholder="Phone Number"
                        onFocus={() => handleFieldFocus('phoneNumber')}
                        onBlur={() => handleFieldBlur('phoneNumber')}
                      />
                      {errors.phoneNumber && (
                        <div className="form-error">{errors.phoneNumber}</div>
                      )}
                      {focusedFields.phoneNumber && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${
                              errors.phoneNumber
                                ? 'error-title'
                                : 'correct-title'
                            }`}
                          >
                            Phone Number
                          </span>
                        </div>
                      )}
                    </div>
                    <div
                      className="form-item  w-calc-50_minus_0_625rem"
                      ref={dropdownRef}
                    >
                      <Field
                        type="text"
                        name="country"
                        className={`form-control ${selectedCountry?.country ? '' : 'error-border'} `}
                        placeholder="Your Country"
                        onFocus={() => handleFieldFocus('country')}
                        onBlur={() => handleFieldBlur('country')}
                        value={inputCountryValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const inputCountryValue = e.target.value;
                          setInputCountryValue(inputCountryValue);
                          filterCountries(inputCountryValue);
                        }}
                      />
                      {filteredCountries.length > 0 && countryDropdownOpen && (
                        <div className="dropdown-country-menu">
                          {filteredCountries.map((country, index) => (
                            <div
                              key={`${country.code}-${index}`}
                              onClick={() => {
                                setInputCountryValue(country.country);
                                setCountryDropdownOpen(false);
                              }}
                              className="dropdown-country-item"
                            >
                              <span>{country.country}</span>
                            </div>
                          ))}
                        </div>
                      )}
                      {!selectedCountry && (
                        <div className="form-error">Country is required</div>
                      )}
                      <div
                        className="country-dropdown-icon"
                        onClick={toggleCountryDropdown}
                      >
                        <RectangleDownIcon />
                      </div>
                      {focusedFields.country && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${
                              selectedCountry ? 'correct-title' : 'error-title'
                            }`}
                          >
                            Your Country
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex-row form-row mb-1_25rem">
                    <div className="form-item  w-100">
                      <Field
                        type="text"
                        name="email"
                        className={`form-control ${errors.email ? 'error-border' : ''} `}
                        placeholder="Mail Address"
                        onFocus={() => handleFieldFocus('email')}
                        onBlur={() => handleFieldBlur('email')}
                      />
                      {errors.email && (
                        <div className="form-error">{errors.email}</div>
                      )}
                      {focusedFields.email && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${errors.email ? 'error-title' : 'correct-title'}`}
                          >
                            Mail Address
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex-row form-row mb-2rem w-100">
                    <div className="form-item w-calc-50_minus_0_625rem mr-1_25rem ">
                      <Field
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        className={`form-control ${errors.password ? 'error-border' : ''} `}
                        placeholder="Password"
                        onFocus={() => handleFieldFocus('password')}
                        onBlur={() => handleFieldBlur('password')}
                      />
                      {errors.password && (
                        <div className="form-error">{errors.password}</div>
                      )}
                      {focusedFields.password && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${
                              errors.password ? 'error-title' : 'correct-title'
                            }`}
                          >
                            Password
                          </span>
                        </div>
                      )}
                      <div
                        className="show-password-container"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <HidePasswordIcon />
                        ) : (
                          <ShowPasswordIcon />
                        )}
                      </div>
                    </div>
                    <div className="form-item w-calc-50_minus_0_625rem ">
                      <Field
                        type={showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        className={`form-control ${errors.confirmPassword ? 'error-border' : ''} `}
                        placeholder="Confirm Password"
                        onFocus={() => handleFieldFocus('confirmPassword')}
                        onBlur={() => handleFieldBlur('confirmPassword')}
                      />
                      {errors.confirmPassword && (
                        <div className="form-error">
                          {errors.confirmPassword}
                        </div>
                      )}
                      {focusedFields.confirmPassword && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${
                              errors.confirmPassword
                                ? 'error-title'
                                : 'correct-title'
                            }`}
                          >
                            Confirm Password
                          </span>
                        </div>
                      )}
                      <div
                        className="show-password-container"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? (
                          <HidePasswordIcon />
                        ) : (
                          <ShowPasswordIcon />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="checkbox-form mb-2rem">
                    <label>
                      <Field
                        type="checkbox"
                        name="agreeToPolicy"
                        className="checkbox-input"
                      />{' '}
                      I agree to Square’s
                      <Link
                        to="/auth/error-404"
                        className="secondary-link-roboto"
                      >
                        Cookie
                      </Link>
                      and
                      <Link
                        to="/auth/privacy-policy"
                        className="secondary-link-roboto"
                      >
                        Privacy Policy.
                      </Link>
                    </label>
                    {values?.agreeToPolicy && (
                      <div
                        className="checkbox-active"
                        onClick={() => setFieldValue('agreeToPolicy', false)}
                      >
                        <CheckboxActiveIcon />
                      </div>
                    )}
                    {errors.agreeToPolicy && (
                      <div className="form-error">{errors.agreeToPolicy}</div>
                    )}
                  </div>

                  <div className="d-flex-row form-row w-100">
                    <button
                      type="submit"
                      className="btn btn-secondary mr-0_625rem w-9_375rem"
                      onClick={handleNavigateToLogin}
                    >
                      Login
                    </button>

                    <button
                      type="submit"
                      className="btn btn-primary w-calc-100_minus_10rem"
                      disabled={isSubmitting || !isValid || !selectedCountry}
                    >
                      {isSubmitting ? 'Submitting...' : 'Get Started'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="d-flex-center h-100 w-50 z2 p-relative">
        <div className="d-flex-start-start w-80">
          <img src={LoginPNG} alt="login" className="w-100 z-2" />
        </div>
      </div>
    </div>
  );
};

export default Registration;
