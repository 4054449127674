import React, { useState, useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';

import {
  StartCampaignActionButtons,
  StartCampaignHeader,
  StartCampaignTable,
  CampaignSettings,
  ConvertOnTheSpotView,
  ReminderView,
  CustomView,
  ChooseMediaView,
  ChoosedMediaView,
  ApprovedMessagesView,
  AddNewView,
  NurtureToAppointment,
  EditTemplateView,
} from './components';
import { useAppData } from '../../context/AppContext';
import {
  campaignViewValues,
  StartCampaignConfigProps,
  StartCampaignConfig,
} from '../../config/CampaignsConfig';
import { CampaignDataProps } from './components/startCampaignModels';
import { getCampaignById } from '../../core/requests/requests';
import ConnectSocialMediaModal from './components/ConnectSocialMediaModal';
import ConnectFacebookAdsModal from './components/ConnectFacebookAdsModal';
import ConnectInstagramAdsModal from './components/ConnectInstagramAdsModal';

const StartCampaign: React.FC = () => {
  const {
    currentStartCampaignView,
    setCurrentStartCampaignView,
    setCurrentCampaign,
    currentCampaign,
    setShowModalBackground,
  } = useAppData();
  const { campaignId } = useParams<{ campaignId: string }>();

  const [currentMessageType, setCurrentMessageType] = useState<string | null>(
    null
  );
  const [currentCampaignConfig, setCurrentCampaignConfig] =
    useState<StartCampaignConfigProps | null>(null);
  const [campaignData, setCampaignData] = useState<CampaignDataProps | null>(
    null
  );
  const [showConnectFacebookModal, setShowConnectFacebookModal] =
    useState<boolean>(false);
  const [showConnectInstagramModal, setShowConnectInstagramModal] =
    useState<boolean>(false);
  const [showConnectFacebookAdsModal, setShowConnectFacebookAdsModal] =
    useState<boolean>(false);
  const [showConnectInstagramAdsModal, setShowConnectInstagramAdsModal] =
    useState<boolean>(false);

  const handleCloseConnectFacebook = () => {
    setShowConnectFacebookModal(false);
    setShowModalBackground(false);
  };

  const handleCloseConnectFacebookAds = () => {
    setShowConnectFacebookAdsModal(false);
    setShowModalBackground(false);
  };

  const handleCloseConnectInstagram = () => {
    setShowConnectInstagramModal(false);
    setShowModalBackground(false);
  };

  const handleCloseConnectInstagramAds = () => {
    setShowConnectInstagramAdsModal(false);
    setShowModalBackground(false);
  };

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        if (campaignId) {
          const { data } = await getCampaignById(campaignId);
          setCampaignData(data);
          setCurrentCampaign(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCampaign();
  }, [campaignId]);

  useEffect(() => {
    const currentViewConfig = () => {
      return StartCampaignConfig.filter(
        (config) => config.value === currentStartCampaignView
      )[0];
    };

    const currentConfig = currentViewConfig();
    setCurrentCampaignConfig(currentConfig);
  }, [currentStartCampaignView]);

  useEffect(() => {
    if (
      currentCampaign &&
      currentCampaign?.id &&
      !currentCampaign?.isFacebookLoginIn &&
      currentCampaign.platforms &&
      (currentCampaign.platforms as string[]).includes('FACEBOOK')
    ) {
      setShowModalBackground(true);
      setShowConnectFacebookModal(true);
    } else if (
      currentCampaign &&
      currentCampaign?.id &&
      !currentCampaign?.isFacebookLoginIn &&
      currentCampaign.platforms &&
      (currentCampaign.platforms as string[]).includes('INSTAGRAM')
    ) {
      setShowModalBackground(true);
      setShowConnectInstagramModal(true);
    } else if (
      currentCampaign &&
      currentCampaign?.id &&
      currentCampaign?.isFacebookLoginIn &&
      !currentCampaign?.isFacebookAdConnected &&
      currentCampaign.platforms &&
      (currentCampaign.platforms as string[]).includes('FACEBOOK')
    ) {
      setShowConnectFacebookAdsModal(true);
      setShowModalBackground(true);
    } else if (
      currentCampaign &&
      currentCampaign?.id &&
      currentCampaign?.isFacebookLoginIn &&
      !currentCampaign?.isInstagramConnected &&
      currentCampaign.platforms &&
      (currentCampaign.platforms as string[]).includes('INSTAGRAM')
    ) {
      setShowConnectInstagramAdsModal(true);
      setShowModalBackground(true);
    }
  }, [currentCampaign]);

  useEffect(() => {
    const savedView = sessionStorage.getItem('currentStartCampaignView');
    if (savedView) {
      setCurrentStartCampaignView(savedView); // Restore the view from session storage
      sessionStorage.removeItem('currentStartCampaignView'); // Clean up the session storage
    }
  }, []);

  return (
    <div className="p-1_875rem">
      <div className="d-flex-start-start b-radius-0_5rem p-1_5rem shadow-box-1 shadow-box-2 bg-white w-100">
        <StartCampaignHeader
          currentCampaignConfig={currentCampaignConfig}
          campaignDataId={campaignData?.id ? campaignData?.id : null}
        />
        {currentStartCampaignView === campaignViewValues?.startCampaign && (
          <StartCampaignActionButtons />
        )}
        {currentStartCampaignView === campaignViewValues?.convertOnTheSpot && (
          <ConvertOnTheSpotView setCurrentMessageType={setCurrentMessageType} />
        )}
        {currentStartCampaignView === campaignViewValues?.appointment && (
          <NurtureToAppointment setCurrentMessageType={setCurrentMessageType} />
        )}
        {currentStartCampaignView === campaignViewValues?.reminder && (
          <ReminderView setCurrentMessageType={setCurrentMessageType} />
        )}
        {currentStartCampaignView === campaignViewValues?.custom && (
          <CustomView />
        )}

        {(currentStartCampaignView === campaignViewValues?.instagram ||
          currentStartCampaignView === campaignViewValues?.facebook ||
          currentStartCampaignView === campaignViewValues?.whatsapp ||
          currentStartCampaignView === campaignViewValues?.sms) && (
          <ChoosedMediaView />
        )}
        {currentStartCampaignView === campaignViewValues?.approved_messages && (
          <ApprovedMessagesView currentMessageType={currentMessageType} />
        )}
        {currentStartCampaignView === campaignViewValues?.add_new && (
          <AddNewView
            currentMessageType={currentMessageType ? currentMessageType : null}
          />
        )}
        {currentStartCampaignView === campaignViewValues?.edit && (
          <EditTemplateView />
        )}
        {currentStartCampaignView === campaignViewValues?.chooseMedia && (
          <ChooseMediaView />
        )}
        {currentCampaignConfig?.showLeads && <StartCampaignTable />}
        {currentStartCampaignView === campaignViewValues?.settings && (
          <CampaignSettings
            campaignData={campaignData}
            setCampaignData={setCampaignData}
          />
        )}
        {showConnectFacebookModal && (
          <ConnectSocialMediaModal
            onClose={handleCloseConnectFacebook}
            isFacebook={true}
          />
        )}
        {showConnectInstagramModal && (
          <ConnectSocialMediaModal
            onClose={handleCloseConnectInstagram}
            isFacebook={false}
          />
        )}
        {showConnectFacebookAdsModal && (
          <ConnectFacebookAdsModal onClose={handleCloseConnectFacebookAds} />
        )}

        {showConnectInstagramAdsModal && (
          <ConnectInstagramAdsModal onClose={handleCloseConnectInstagramAds} />
        )}
      </div>
    </div>
  );
};

export default memo(StartCampaign);
