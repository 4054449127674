import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { TriangleIcon } from '../../../assets';
import { DashboardDropdownsConfig } from '../../../config/dashboardConfig';
import { AnalyticsHeaderProps } from './analyticsModels';
import { GenerateReportButton } from './';

const AnalyticsHeader: React.FC<AnalyticsHeaderProps> = ({
  setAnalyticsQueryString,
  analyticsQueryString,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownState, setDropdownState] = useState('This year');
  const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);
  const defaultStartDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
  const defaultEndDate = moment().format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);

  // Temporary state for the date range selector
  const [tempStartDate, setTempStartDate] = useState<string>(startDate);
  const [tempEndDate, setTempEndDate] = useState<string>(endDate);

  // Handle close date range selector
  const handleCloseDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(false);
  };

  // Function to update the query string
  const updateQueryString = (start: string, end: string, type: string) => {
    const queryString = `?startDate=${start}&endDate=${end}&type=${type}`;
    setAnalyticsQueryString(queryString);
  };

  // Handle dropdown selection
  const handleDropdownSelect = (option: string) => {
    setDropdownState(option);
    setShowDropdown(false);

    let start, end, type;

    switch (option) {
      case 'This year':
        start = moment().startOf('year').format('YYYY-MM-DD');
        end = moment().endOf('year').format('YYYY-MM-DD');
        type = 'month';
        break;
      case 'This Month':
        start = moment().startOf('month').format('YYYY-MM-DD');
        end = moment().endOf('month').format('YYYY-MM-DD');
        type = 'week';
        break;
      case 'This Week':
        start = moment().startOf('week').format('YYYY-MM-DD');
        end = moment().endOf('week').format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Today':
        start = moment().format('YYYY-MM-DD');
        end = moment().format('YYYY-MM-DD');
        type = 'day';
        break;
      case 'Custom Date Range':
        setIsDateRangeSelectorOpen(true);
        return; // Skip generating query string until custom date is selected
      default:
        return;
    }

    setStartDate(start);
    setEndDate(end);
    updateQueryString(start, end, type);
  };

  // Handle custom date selection
  const handleCustomDateChange = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

    setTempStartDate(formattedStartDate);
    setTempEndDate(formattedEndDate);
  };

  // Handle saving the selected custom date range
  const handleSaveDateRange = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    updateQueryString(tempStartDate, tempEndDate, 'custom');
    setIsDateRangeSelectorOpen(false);
  };

  return (
    <div className="d-flex-row-space-between pl-1_5rem w-100">
      <div className="d-flex-start-baseline-row p-relative">
        <h3 className="ff-poppins fw-600 c-regular fs-1_5rem lh-2_25rem pr-1_375rem ">
          Analytics
        </h3>
        <div className="d-flex-row p-relative">
          <span className="ff-inter c-secondary fs-1_125rem lh-1_125rem">
            Show:{' '}
          </span>
          <div
            className="d-flex-row hovered"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <span className="ff-inter c-main fs-1_125rem pl-0_375rem">
              {startDate && endDate && dropdownState === 'Custom Date Range'
                ? `${startDate} - ${endDate}`
                : dropdownState}
            </span>
            <div className="d-flex-center pl-0_375rem">
              <TriangleIcon
                className={`${showDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
              />
            </div>
          </div>
          {showDropdown && (
            <div className="p-absolute left-_-0_5rem top-1_4rem z1 bg-white mw-12rem pt-0_5rem shadow-box-8">
              {DashboardDropdownsConfig.dashboardOptions.map(
                (option, index) => (
                  <div
                    className="hovered p-0_2rem-0"
                    key={index}
                    onClick={() => handleDropdownSelect(option)}
                  >
                    <span className="ff-inter c-main fs-1_125rem pl-0_375rem">
                      {option}
                    </span>
                  </div>
                )
              )}
            </div>
          )}
        </div>
        {isDateRangeSelectorOpen && (
          <div className="d-flex-center p-absolute right-0 top-2_8rem z4 shadow-box-10 bg-white">
            <DateRange
              ranges={[
                {
                  startDate: new Date(tempStartDate || startDate),
                  endDate: new Date(tempEndDate || endDate),
                  key: 'selection',
                },
              ]}
              onChange={handleCustomDateChange}
            />
            <div className="d-flex-row-space-between w-100 p-1rem">
              <button
                className="btn btn-secondary w-calc-50_minus_0_5rem"
                onClick={handleCloseDateRangeSelector}
              >
                Close
              </button>
              <button
                className="btn btn-primary w-calc-50_minus_0_5rem"
                onClick={handleSaveDateRange}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
      <GenerateReportButton analyticsQueryString={analyticsQueryString} />
    </div>
  );
};

export default AnalyticsHeader;
