import React from 'react';
import {
  ConversationsChart,
  DashboardLeadsCharts,
  ReachChart,
  RepliesChart,
  WarmLeadsCharts,
} from './';
import { DashboardChartsProps } from './dashboardModels';

const DashboardCharts: React.FC<DashboardChartsProps> = ({
  dashboardStatisticsData,
}) => {
  return (
    <div className="d-flex-row w-100 h-18rem bg-white  mt-1_875rem b-radius-1_25rem">
      <DashboardLeadsCharts
        dashboardStatisticsData={dashboardStatisticsData ?? null}
      />
      <ReachChart dashboardStatisticsData={dashboardStatisticsData ?? null} />
      <WarmLeadsCharts
        dashboardStatisticsData={dashboardStatisticsData ?? null}
      />
      <ConversationsChart
        dashboardStatisticsData={dashboardStatisticsData ?? null}
      />
      <RepliesChart dashboardStatisticsData={dashboardStatisticsData ?? null} />
    </div>
  );
};

export default DashboardCharts;
