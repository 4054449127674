import React, { useState } from 'react';

import { AddIcon } from '../../../assets';
import { AgentBioTableProps } from './settingsModels';
import { ConfirmModal } from '../../../components';
import { useAppData } from '../../../context/AppContext';
import { AddAgentBioModal } from './';
import { deleteAgent } from '../../../core/requests/requests';

const AgentBioTable: React.FC<AgentBioTableProps> = ({
  agentsData,
  updateAgentsList,
}) => {
  const { setShowModalBackground } = useAppData();
  const [activeAgentId, setActiveAgentId] = useState<string | null>(null);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    useState<boolean>(false);

  const handleShowConfirmDeleteModal = () => {
    setShowModalBackground(true);
    setShowConfirmDeleteModal(true);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setShowModalBackground(false);
    setActiveAgentId(null);
  };

  const handleShowEditModal = () => {
    setShowModalBackground(true);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowModalBackground(false);
    setShowEditModal(false);
    setActiveAgentId(null);
  };

  const handleDeleteAgent = async (activeAgentId: string) => {
    try {
      if (activeAgentId) {
        const response = await deleteAgent(activeAgentId);
        if (response.status === 200 || response.status === 204) {
          updateAgentsList();
          handleCloseConfirmDeleteModal();
        } else {
          console.log(`Unexpected response status: ${response.status}`);
        }
      }
    } catch (error) {
      console.log('Error deleting agent:', error);
    }
  };

  const handleToggleOptions = (agentId: string) => {
    setActiveAgentId((prevId) => (prevId === agentId ? null : agentId));
  };

  return (
    <>
      <div className="d-flex-start-start w-100">
        {agentsData && agentsData?.length > 0 ? (
          <div className="d-flex-start-start w-100">
            <table className="settings-agent-bio-table mt-1_5rem">
              <thead>
                <tr>
                  <th>
                    <div className="d-flex-center"></div>
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Title</th>
                  <th>Country</th>
                  <th>City</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {agentsData.map((agent) => (
                  <tr key={agent.id}>
                    <td>
                      <div className="d-flex-center"></div>
                    </td>
                    <td className="agent-bio-table-name">{`${agent?.name}`}</td>
                    <td>{agent.email}</td>
                    <td>{agent?.title || 'N/A'}</td>
                    <td>{agent?.country || 'N/A'}</td>
                    <td>{agent?.city}</td>
                    <td>
                      <div className="d-flex-start-start w-1rem icon-hovered p-relative">
                        <div
                          className="d-flex-center"
                          onClick={() => handleToggleOptions(agent?.id || '')}
                        >
                          <AddIcon />
                        </div>
                        {activeAgentId === agent?.id && (
                          <div className="d-flex-start-start p-absolute top-1_5rem right-_-1rem bg-white w-8rem p-0_5rem-0 z2 shadow-box-8">
                            <div
                              className="d-flex-row w-100 p-0_5rem text-hover-primary"
                              onClick={handleShowEditModal}
                            >
                              <span>Edit agent</span>
                            </div>
                            <div
                              className="d-flex-row w-100 p-0_5rem text-hover-danger"
                              onClick={handleShowConfirmDeleteModal}
                            >
                              <span>Remove agent</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex-center w-100 p-1_5rem">
            <span className="fs-1_5rem text-header-description fw-600">
              No agents are available.
            </span>
          </div>
        )}
      </div>
      {showConfirmDeleteModal && (
        <div className="d-flex-center p-fixed top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to delete this agent?"
            onConfirm={() => handleDeleteAgent(activeAgentId || '')}
            onCancel={handleCloseConfirmDeleteModal}
          />
        </div>
      )}
      {showEditModal && (
        <div className="d-flex-center p-fixed top-0 left-0 size-max z4">
          <AddAgentBioModal
            onClose={handleCloseEditModal}
            updateAgentsList={updateAgentsList}
            agentId={activeAgentId || ''}
            agentData={agentsData.find((agent) => agent.id === activeAgentId)}
          />
        </div>
      )}
    </>
  );
};

export default AgentBioTable;
