import React from 'react';
import { Formik, Form, Field } from 'formik';
import { HelpImagePNG } from '../../assets';

const Help: React.FC = () => {
  return (
    <div className="d-flex-start-center w-100 p-relative">
      <div className="d-flex-start-center p-absolute top-0 left-0 z0 h-25rem bg-white w-100"></div>
      <div className="d-flex-start-center w-44rem z1 pt-4_25rem">
        <div className="d-flex-center w-8rem h-8rem">
          <img src={HelpImagePNG} alt="help" className="w-100" />
        </div>
        <div className="d-flex-center w-100 pt-0_5rem">
          <span className="ff-poppins fw-600 fs-2_625rem ls-0_01rem c-171725">
            How Can We Help?
          </span>
        </div>

        {/* Formik form */}
        <div className="d-flex-start-start w-100 p-1_5rem b-1 bg-white mt-1_5rem b-radius-1_25rem">
          <Formik
            initialValues={{
              subject: '',
              description: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              // Handle form submission
              console.log(values);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="w-100">
                <div className="d-flex-start-start w-100 mb-1rem">
                  <label
                    htmlFor="subject"
                    className="ff-inter fw-500 pb-0_375rem fs-0_875rem"
                  >
                    Subject
                  </label>
                  <Field
                    type="text"
                    name="subject"
                    placeholder="Title"
                    className="payment-details-input w-100"
                  />
                </div>

                <div className="d-flex-start-start w-100 mb-1_5rem">
                  <label
                    htmlFor="description"
                    className="ff-inter fw-500 pb-0_375rem fs-0_875rem"
                  >
                    Description
                  </label>
                  <Field
                    as="textarea"
                    name="description"
                    placeholder="Message"
                    className="payment-details-textarea w-100"
                  />
                </div>

                <div className="d-flex-center">
                  <button
                    type="submit"
                    className="btn w-100 bg-1e75ff  p-0_75rem-1_5rem b-radius-0_5rem"
                    disabled={isSubmitting}
                  >
                    <span className="c-fafafb fs-0_875rem fw-600">
                      Send Message
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Help;
