export function formatStatisticValue(num: number | null | undefined): string {
  // Handle null or undefined values
  if (num === null || num === undefined) {
    return '0'; // Return '0' or any other default value as needed
  }

  if (num < 1000) {
    return num.toString();
  } else {
    // Divide by 1000 and round to 1 decimal place
    const roundedNum = (num / 1000).toFixed(1);

    // Remove trailing .0 if it's an integer after rounding
    return `${roundedNum.endsWith('.0') ? parseInt(roundedNum) : roundedNum}k`;
  }
}

export function calculateStatisticsPercentage(
  value: number | null | undefined,
  prevValue: number | null | undefined
) {
  // Handle the case where either value is null or undefined
  if (
    value === null ||
    value === undefined ||
    prevValue === null ||
    prevValue === undefined
  ) {
    return '0%'; // Return 0% if either value is null or undefined
  }

  // Handle the case where previous value is zero
  if (prevValue === 0) {
    return value > 0 ? '100%' : '0%'; // If there are leads now, consider it a 100% increase; otherwise, it's 0%
  }

  const changeRate = ((value - prevValue) / prevValue) * 100; // Calculate the change rate
  return `${Math.round(changeRate)}%`;
}
