import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CampaignsTableProps } from '../../startCampaign/components/startCampaignModels';
import { AddIcon } from '../../../assets';
import { getFormattedStringFromDate } from '../../../helpers/campaignHelpers';
import { useAppData } from '../../../context/AppContext';
import { campaignViewValues } from '../../../config/CampaignsConfig';
import {
  deleteCampaignById,
  stopCampaignById,
} from '../../../core/requests/requests';
import { ConfirmModal } from '../../../components';

const CampaignsTable: React.FC<CampaignsTableProps> = ({
  currentCampaigns,
  fetchCampaigns,
}) => {
  const navigate = useNavigate();
  const {
    setCurrentStartCampaignView,
    setCurrentCampaign,
    setShowModalBackground,
  } = useAppData();
  const [activeCampaignId, setActiveCampaignId] = useState<string | null>(null);
  const [showStopCampaignConfirmModal, setShowStopCampaignConfirmModal] =
    useState(false);

  const [showDeleteCampaignConfirmModal, setShowDeleteCampaignConfirmModal] =
    useState(false);

  const handleShowStopCampaignConfirmModal = () => {
    setShowModalBackground(true);
    setShowStopCampaignConfirmModal(true);
  };

  const handleCloseStopCampaignConfirmModal = () => {
    setShowStopCampaignConfirmModal(false);
    setShowModalBackground(false);
    setActiveCampaignId(null);
  };

  const handleShowDeleteCampaignConfirmModal = () => {
    setShowModalBackground(true);
    setShowDeleteCampaignConfirmModal(true);
  };

  const handleCloseDeleteCampaignConfirmModal = () => {
    setShowDeleteCampaignConfirmModal(false);
    setShowModalBackground(false);
    setActiveCampaignId(null);
  };

  const handleNavigateToCampaign = (campaignId: string) => {
    navigate(`/campaigns/${campaignId}`);
  };

  const handleToggleOptions = (campaignId: string) => {
    setActiveCampaignId((prevId) =>
      prevId === campaignId ? null : campaignId
    );
  };

  const handleDeleteCampaign = async (campaignId: string) => {
    try {
      if (campaignId) {
        const response = await deleteCampaignById(campaignId);
        if (response?.data) {
          fetchCampaigns();
          handleCloseDeleteCampaignConfirmModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStopCampaign = async (campaignId: string) => {
    try {
      if (campaignId) {
        const response = await stopCampaignById(campaignId);
        if (response?.data) {
          fetchCampaigns();
          handleCloseStopCampaignConfirmModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigateToSettings = (campaignId: string) => {
    const selectedCampaign = currentCampaigns.find(
      (campaign) => campaign.id === campaignId
    );

    if (selectedCampaign) {
      setCurrentCampaign(selectedCampaign);
    }
    setCurrentStartCampaignView(campaignViewValues?.settings);
    navigate(`/leads/start-new-campaign/${campaignId}`);
  };

  return (
    <div className="w-100 pb-2rem p-relative">
      <table className="leads-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Platform</th>
            <th>Status</th>
            <th>Duration</th>
            <th>Creation Date</th>
            <th>Conversations</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentCampaigns.map((campaign) => (
            <tr key={campaign?.id}>
              <td
                className="leads-table-name text-hover-primary"
                onClick={() => handleNavigateToCampaign(campaign?.id || '')}
              >
                <span>{campaign?.name}</span>
              </td>
              <td>{campaign?.platforms}</td>
              <td>
                {campaign?.status === 'ACTIVE' ? (
                  <span className="fw-600 c-0e9f6e">Active</span>
                ) : null}
                {campaign?.status === 'NEW' ? (
                  <span className="fw-600 c-0062ff">New</span>
                ) : null}
                {campaign?.status === 'STOPPED' ? (
                  <span className="fw-600 c-e73f3f">Stopped</span>
                ) : null}
                {campaign?.status === 'CLOSED' ? (
                  <span className="fw-600 c-ffc542">Closed</span>
                ) : null}
              </td>
              <td>
                {`${getFormattedStringFromDate(campaign?.startDate ? new Date(campaign?.startDate) : null)} - ${getFormattedStringFromDate(campaign?.endDate ? new Date(campaign?.endDate) : null)}` ||
                  'N/A'}
              </td>
              <td>
                {getFormattedStringFromDate(
                  campaign?.createdAt ? new Date(campaign?.createdAt) : null
                )}
              </td>
              <td>{campaign?.conversationsCount || 0}</td>
              <td>
                <div className="d-flex-start-start w-1rem icon-hovered p-relative">
                  <div
                    className="d-flex-center"
                    onClick={() => handleToggleOptions(campaign?.id || '')}
                  >
                    <AddIcon />
                  </div>
                  {activeCampaignId === campaign?.id && (
                    <div className="d-flex-start-start p-absolute top-1_5rem right-_-1rem bg-white w-8rem p-0_5rem-0 z2 shadow-box-8">
                      <div
                        className="d-flex-row w-100 p-0_5rem text-hover-primary"
                        onClick={() =>
                          handleNavigateToSettings(campaign?.id || '')
                        }
                      >
                        <span>Edit campaign</span>
                      </div>
                      <div
                        className="d-flex-row w-100 p-0_5rem text-hover-primary"
                        onClick={handleShowStopCampaignConfirmModal}
                      >
                        <span>Stop campaign</span>
                      </div>
                      <div
                        className="d-flex-row w-100 p-0_5rem text-hover-danger"
                        onClick={handleShowDeleteCampaignConfirmModal}
                      >
                        <span>Delete campaign</span>
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showStopCampaignConfirmModal && (
        <div className="d-flex-center p-fixed top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to stop this campaign?"
            onConfirm={() => handleStopCampaign(activeCampaignId || '')}
            onCancel={handleCloseStopCampaignConfirmModal}
          />
        </div>
      )}
      {showDeleteCampaignConfirmModal && (
        <div className="d-flex-center p-fixed top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to delete this campaign?"
            onConfirm={() => handleDeleteCampaign(activeCampaignId || '')}
            onCancel={handleCloseDeleteCampaignConfirmModal}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignsTable;
