import React from 'react';
import { GenerateReportIcon } from '../../../assets';
import { exportStatsByUserId } from '../../../core/requests/requests';
import { ExportStatsTypes } from '../../../config/dashboardConfig';
import { useAuth } from '../../../context/AuthContext';
import { GenerateDashboardReportButtonProps } from './dashboardModels';

const GenerateDashboardReportButton: React.FC<
  GenerateDashboardReportButtonProps
> = ({ dashboardQueryString }) => {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const userId = currentUser?.id;
  const exportStatsType = ExportStatsTypes?.dashboard;

  const handleGenerateReport = async () => {
    try {
      if (userId) {
        setIsLoading(true);
        const { data } = await exportStatsByUserId(
          userId,
          exportStatsType,
          dashboardQueryString
        );
        if (data) {
          downloadCSV(data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to download data as CSV
  const downloadCSV = (csvData: string) => {
    const csvContent = csvData;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create download link and trigger download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `dashboard_report_${new Date().toISOString().slice(0, 10)}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="d-flex-center">
      <button
        className="btn-generate-report"
        onClick={handleGenerateReport}
        disabled={isLoading}
      >
        <div className="d-flex-center mr-0_5rem">
          <GenerateReportIcon />
        </div>
        {isLoading ? (
          <span className="ff-poppins fs-0_875rem c-fafafb fw-600">
            Generating...
          </span>
        ) : (
          <span className="ff-poppins fs-0_875rem c-fafafb fw-600">
            Generate Report
          </span>
        )}
      </button>
    </div>
  );
};

export default GenerateDashboardReportButton;
