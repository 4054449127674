import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LayoutBuilder from '../layout/LayoutBuilder';
import {
  Dashboard,
  AIWarmLeadsCampaigns,
  SavedLeads,
  EmailAssistant,
  Conversations,
  Analytics,
  Calendar,
  Settings,
  Integrations,
  Support,
  Leads,
  FindColdLeads,
  Highlights,
  CampaignById,
  Help,
} from '../pages';
import { HubSpotRedirectPage } from '../pages/leads/components';
import StartCampaign from '../pages/startCampaign/StartCampaign';
import Campaigns from '../pages/campaigns/Campaigns';
import Profile from '../pages/profile/Profile';
import FacebookRedirectPage from '../pages/facebookRedirect/FacebookRedirectPage';

const PrivateRoutes: React.FC = () => {
  return (
    <LayoutBuilder>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/leads/find-cold-leads" element={<FindColdLeads />} />
        <Route path="/leads/saved-leads" element={<SavedLeads />} />
        <Route
          path="/leads/ai-warm-lead-campaigns"
          element={<AIWarmLeadsCampaigns />}
        />
        <Route path="/leads/start-new-campaign" element={<StartCampaign />} />
        <Route
          path="/leads/start-new-campaign/:campaignId"
          element={<StartCampaign />}
        />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/campaigns/:campaignId" element={<CampaignById />} />
        <Route
          path="/callback/hubspot-callback"
          element={<HubSpotRedirectPage />}
        />
        <Route path="/email-assistant" element={<EmailAssistant />} />
        <Route path="/conversations" element={<Conversations />} />
        <Route path="/conversations/:chatId" element={<Highlights />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/support" element={<Support />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/help" element={<Help />} />
        <Route
          path="/integration/facebook/auth/callback"
          element={<FacebookRedirectPage />}
        />
      </Routes>
    </LayoutBuilder>
  );
};

export default PrivateRoutes;
