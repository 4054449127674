import React, { useState, useEffect } from 'react';

import { useAppData } from '../../../../context/AppContext';
import { FacebookAdsProps } from '../../../../core/types/_models';
import { getFacebookAdAccounts } from '../../../../core/requests/requests';
import { CloseIcon, SearchIcon } from '../../../../assets';
import { ConnectFacebookAddIdProps } from '../startCampaignModels';

const ConnectFacebookAddId: React.FC<ConnectFacebookAddIdProps> = ({
  chosenFacebookAdAccountId,
  setChosenFacebookAdAccountId,
  setSelectedFacebookAdId,
}) => {
  const { currentCampaign } = useAppData();
  const [searchInput, setSearchInput] = useState<string>('');
  const [facebookAdsData, setFacebookAdsData] = useState<FacebookAdsProps[]>(
    []
  );
  const [filteredFacebookAds, setFilteredFacebookAds] =
    useState<FacebookAdsProps[]>(facebookAdsData);

  useEffect(() => {
    const fetchFacebookAds = async () => {
      try {
        if (currentCampaign && currentCampaign.id) {
          const { data } = await getFacebookAdAccounts(currentCampaign.id);
          if (data) {
            setFacebookAdsData(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchFacebookAds();
  }, [currentCampaign]);

  useEffect(() => {
    if (searchInput) {
      setFilteredFacebookAds(
        facebookAdsData.filter((facebookAd) =>
          facebookAd.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    } else {
      setFilteredFacebookAds(facebookAdsData);
    }
  }, [searchInput, facebookAdsData]);

  const handleFacebookAccountAdIdChange = (facebookAdAccountId: string) => {
    if (chosenFacebookAdAccountId === facebookAdAccountId) {
      setChosenFacebookAdAccountId(null);
      setSelectedFacebookAdId(null);
    } else {
      setChosenFacebookAdAccountId(facebookAdAccountId);
    }
  };

  const handleClearInput = () => {
    setSearchInput('');
  };

  return (
    <div className="d-flex-start-start w-100 p-1rem">
      <div className="d-flex-center w-100">
        <div className="d-flex-row w-100 p-relative">
          <input
            className="settings-modal-input"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <div className="d-flex-center p-absolute top-0_8rem left-1rem">
            <SearchIcon />
          </div>
          {searchInput.length > 0 && (
            <div
              className="d-flex-center p-absolute top-0_8rem right-1rem icon-hovered"
              onClick={handleClearInput}
            >
              <CloseIcon />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex-start-start w-100">
        {filteredFacebookAds.length > 0 ? (
          filteredFacebookAds
            .filter(
              (facebookAd) =>
                !chosenFacebookAdAccountId ||
                chosenFacebookAdAccountId === facebookAd.id
            )
            .map((facebookAd) => (
              <div
                className={`${
                  chosenFacebookAdAccountId !== null &&
                  chosenFacebookAdAccountId !== facebookAd.id
                    ? 'c-9ca3af'
                    : ''
                } d-flex-row w-100 p-1rem bb-1 hovered`}
                key={facebookAd.id}
                onClick={() => handleFacebookAccountAdIdChange(facebookAd.id)}
              >
                <div className="d-flex-center mr-1rem">
                  <input
                    type="checkbox"
                    className="conversation-filter-checkbox"
                    checked={chosenFacebookAdAccountId === facebookAd.id}
                  />
                </div>
                <span className="mr-1rem ff-inter c-111928 fs-0_875rem">
                  {facebookAd.name}
                </span>
              </div>
            ))
        ) : (
          <div className="d-flex-center w-100 p-1rem">
            <span className="ff-inter c-111928 fs-0_875rem">
              No Facebook Ads found
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectFacebookAddId;
