import React, { useState, useEffect } from 'react';
import { AgentSelectionModalProps } from './startCampaignModels';
import { CloseIcon, SearchIcon } from '../../../assets';

const AgentSelectionModal: React.FC<AgentSelectionModalProps> = ({
  onClose,
  onConfirm,
  chosenAgentId,
  agentsData,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>(''); // Search query state
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null); // Only one selected agent

  // Initialize selectedAgent state with chosenAgentId from parent (if any)
  useEffect(() => {
    if (chosenAgentId) {
      setSelectedAgent(chosenAgentId);
    }
  }, []);

  // Handle checkbox selection, allowing only one agent to be selected
  const handleCheckboxChange = (id: string) => {
    setSelectedAgent((prevSelected) => (prevSelected === id ? null : id));
  };

  // Filter agents based on search query
  const filteredAgents = agentsData.filter((agent) => {
    const searchValue = searchQuery.toLowerCase();
    return (
      agent.name.toLowerCase().includes(searchValue) ||
      agent.email.toLowerCase().includes(searchValue) ||
      agent.title.toLowerCase().includes(searchValue) ||
      agent.country.toLowerCase().includes(searchValue)
    );
  });

  // Handle confirm action and pass the selected agent to parent
  const handleConfirm = () => {
    if (selectedAgent) {
      onConfirm(selectedAgent); // Pass the selected agent ID to parent component
    }
    onClose();
  };

  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      <div className="d-flex-start-between w-50rem h-80vh z4 bg-white b-radius-0_5rem">
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem bb-1-l-gray">
          <span className="ff-poppins fw-500 fs-0_875rem c-regular">
            Select Agent:
          </span>
          <div className="d-flex-center icon-hovered" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="d-flex-start-start w-100 h-calc-100-8rem">
          <div className="d-flex-center w-100 p-1rem-1_5rem">
            <div className="d-flex-row w-100 p-relative">
              <input
                className="settings-modal-input"
                placeholder="Search for Agents"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="d-flex-center p-absolute top-0_8rem left-1rem">
                <SearchIcon />
              </div>
            </div>
          </div>
          <div className="d-flex-start-start w-100 pl-1_125rem overflow-auto">
            <table className="agents-modal-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Title</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                {filteredAgents.map((agent) => (
                  <tr key={agent.id}>
                    <td>
                      <input
                        type="checkbox"
                        className="modal-checkbox"
                        checked={selectedAgent === agent.id} // Only one agent can be selected
                        onChange={() => handleCheckboxChange(agent.id)} // Handle checkbox change
                      />
                    </td>
                    <td>{agent.name}</td>
                    <td>{agent.email}</td>
                    <td>{agent.title}</td>
                    <td>{agent.country}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem shadow-box-10">
          <button
            className="btn-cancel w-calc-50_minus_0_25rem"
            onClick={onClose}
          >
            <span>Cancel</span>
          </button>
          <button
            className="btn btn-primary w-calc-50_minus_0_25rem h-2_5rem"
            onClick={handleConfirm} // Call handleConfirm on confirm button click
            disabled={!selectedAgent} // Disable button if no agent is selected
          >
            <span className="ff-inter fw-500 fs-0_875rem">Confirm</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentSelectionModal;
