import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from 'chart.js';
import { AnalyticsLinearChartProps } from './analyticsModels';

// Register the components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const AnalyticsLinearChart: React.FC<AnalyticsLinearChartProps> = ({
  analyticsChartData,
}) => {
  // State to track which datasets are visible
  const [visibleDatasets, setVisibleDatasets] = useState(
    new Array(4).fill(true) // Assuming there are 4 datasets initially visible
  );

  // Extract labels and datasets from analyticsChartData
  const labels = analyticsChartData.map((data) => data.date);

  const allDatasets = [
    {
      label: 'Leads',
      data: analyticsChartData.map((data) => data.newLeads),
      borderColor: '#ff6384',
      backgroundColor: '#ff6384',
      tension: 0.4,
      fill: false,
    },
    {
      label: 'Messages Sent',
      data: analyticsChartData.map((data) => data.messageSent),
      borderColor: '#36a2eb',
      backgroundColor: '#36a2eb',
      tension: 0.4,
      fill: false,
    },
    {
      label: 'Replies',
      data: analyticsChartData.map((data) => data.replies),
      borderColor: '#34c759',
      backgroundColor: '#34c759',
      tension: 0.4,
      fill: false,
    },
    {
      label: 'Meetings Booked',
      data: analyticsChartData.map((data) => data.meetingBooked),
      borderColor: '#8000ffc4',
      backgroundColor: '#8000ffc4',
      tension: 0.4,
      fill: false,
    },
  ];

  const datasets = allDatasets.filter((_, index) => visibleDatasets[index]); // Filter based on visibility

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows height customization
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Disable the default legend
      },
    },
  };

  // Custom styles for the legend

  const legendDotStyles = {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginRight: '0.5rem',
  };

  // Handle legend item click
  const handleLegendClick = (index: number) => {
    setVisibleDatasets((prev) => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index]; // Toggle visibility
      return newVisibility;
    });
  };

  return (
    <div className="d-flex-start-start w-100 bg-white p-1_125rem-1_625rem mt-1_75rem b-radius-1_25rem">
      <div className="d-flex-row mb-1rem">
        {allDatasets.map((dataset, index) => (
          <div
            key={index}
            className="d-flex-row mr-3rem hovered"
            onClick={() => handleLegendClick(index)}
          >
            <div
              style={{
                ...legendDotStyles,
                backgroundColor: dataset.borderColor,
              }}
            ></div>
            <span
              style={{
                fontSize: '14px',
                fontFamily: 'Roboto',
                textDecoration: visibleDatasets[index]
                  ? 'none'
                  : 'line-through', // Cross out the label if dataset is hidden
                color: visibleDatasets[index] ? 'inherit' : 'gray', // Optional: change color to gray if crossed out
              }}
            >
              {dataset.label}
            </span>
          </div>
        ))}
      </div>
      <div
        style={{
          height: '500px',
          width: '100%',
        }}
      >
        <Line data={data} options={options} className="p-1rem" />
      </div>
    </div>
  );
};

export default AnalyticsLinearChart;
