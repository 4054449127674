import React, { useState } from 'react';
import { ConnectInstagramAdsModalProps } from './startCampaignModels';
import { useAppData } from '../../../context/AppContext';
import { CloseIcon } from '../../../assets';
import {
  connectFacebookAddToCampaign,
  connectInstagramAddToCampaign,
} from '../../../core/requests/requests';

import {
  ConnectFacebookAdCampaigns,
  ConnectFacebookAddId,
  ConnectFacebookAdId,
  ConnectInstagramAdId,
} from './connectFacebookAdsModalComponents';

const ConnectInstagramAdsModal: React.FC<ConnectInstagramAdsModalProps> = ({
  onClose,
}) => {
  const { currentCampaign } = useAppData();
  const [chosenInstagramAdAccountId, setChosenInstagramAdAccountId] = useState<
    string | null
  >(null);
  const [isInstagramAdConnected, setIsInstagramAdsConnected] = useState(false);
  const [selectedFacebookAdId, setSelectedFacebookAdId] = useState<
    string | null
  >(null);
  const [chosenFacebookAdAccountId, setChosenFacebookAdAccountId] = useState<
    string | null
  >(null);
  const [selectedFacebookAdCampaignId, setSelectedFacebookAdCampaignId] =
    useState<string | null>(null);

  const [isFacebookAdConnected, setIsFacebookAdConnected] = useState(false);

  const handleConnectFacebook = async () => {
    try {
      if (
        chosenFacebookAdAccountId &&
        currentCampaign?.id &&
        selectedFacebookAdId
      ) {
        const { data } = await connectFacebookAddToCampaign(
          currentCampaign?.id,
          chosenFacebookAdAccountId,
          selectedFacebookAdId
        );
        if (data) {
          setIsFacebookAdConnected(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleConnectInstagram = async () => {
    try {
      if (chosenInstagramAdAccountId && currentCampaign?.id) {
        const { data } = await connectInstagramAddToCampaign(
          currentCampaign?.id,
          chosenInstagramAdAccountId
        );
        if (data) {
          setIsInstagramAdsConnected(true);
          setTimeout(() => {
            setIsInstagramAdsConnected(false);
            onClose();
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      {isInstagramAdConnected ? (
        <div className="d-flex-start-start w-20rem max-h-80vh z4 bg-white p-1_5rem b-radius-0_5rem ">
          <h3 className="text-center pb-1rem pt-1rem">
            Instagram connected successfully!
          </h3>
        </div>
      ) : (
        <div className="d-flex-start-start w-30rem max-h-80vh z4 bg-white b-radius-0_5rem ">
          <div className="d-flex-row-space-between w-100 p-1rem bb-1-l-gray">
            <span className="ff-poppins fw-500 fs-0_875rem c-regular">
              Connect Instagram:
            </span>
            <div className="d-flex-center icon-hovered" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          {isFacebookAdConnected ? (
            <ConnectInstagramAdId
              chosenInstagramAdAccountId={chosenInstagramAdAccountId}
              setChosenInstagramAdAccountId={setChosenInstagramAdAccountId}
            />
          ) : (
            <div className="d-flex-start-start w-100">
              <ConnectFacebookAddId
                chosenFacebookAdAccountId={chosenFacebookAdAccountId}
                setChosenFacebookAdAccountId={setChosenFacebookAdAccountId}
                setSelectedFacebookAdId={setSelectedFacebookAdId}
              />
              {chosenFacebookAdAccountId && (
                <div className="d-flex-start-start w-100">
                  <ConnectFacebookAdCampaigns
                    chosenFacebookAdAccountId={chosenFacebookAdAccountId}
                    setSelectedFacebookAdCampaignId={
                      setSelectedFacebookAdCampaignId
                    }
                    selectedFacebookAdCampaignId={selectedFacebookAdCampaignId}
                  />
                  {selectedFacebookAdCampaignId && (
                    <ConnectFacebookAdId
                      adCampaignId={selectedFacebookAdCampaignId}
                      selectedFacebookAdId={selectedFacebookAdId}
                      setSelectedFacebookAdId={setSelectedFacebookAdId}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          <div className="d-flex-row-space-between w-100 p-1rem">
            <button
              className="btn btn-cancel w-10rem h-2_375rem"
              onClick={onClose}
            >
              <span className="ff-poppins c-black fs-0_75rem fw-600">
                Close
              </span>
            </button>
            {isFacebookAdConnected ? (
              <button
                className="btn btn-primary w-17rem h-2_375rem"
                disabled={chosenInstagramAdAccountId === null}
                onClick={handleConnectInstagram}
              >
                <span className="ff-poppins c-white fs-0_75rem fw-600">
                  Connect
                </span>
              </button>
            ) : (
              <button
                className="btn btn-primary w-17rem h-2_375rem"
                disabled={
                  chosenFacebookAdAccountId === null ||
                  selectedFacebookAdId === null
                }
                onClick={handleConnectFacebook}
              >
                <span className="ff-poppins c-white fs-0_75rem fw-600">
                  Connect
                </span>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectInstagramAdsModal;
