import React from 'react';
import { DashboardInformationIcon, VisibilityDropPNG } from '../../../assets';

const VisibilityDrop: React.FC = () => {
  return (
    <div className="d-flex-row-space-between bg-ebf5ff p-1_5rem w-100 b-radius-0_75rem mb-2rem">
      <div className="d-flex-start-start w-calc-100_minus_20rem">
        <span className="ff-inter fw-500 fs-1_125rem c-0f0f10">
          Visibility Drop
        </span>
        <div className="d-flex-row w-100 p-0_5rem-0">
          <span className="ff-inter fs-0_875rem lh-1_25rem c-888e9e">
            Heads up-there's been a drop in visibility in Hong Kong on phone.
            You've lost 5.28% over the past 28 days. <br /> The most impacted
            pages are:
          </span>
        </div>
        <div className="d-flex-start-start w-100 p-0_5rem-0 w-100">
          <div className="d-flex-row w-100 mb-0_375rem">
            <div className="d-flex-center mr-0_75rem">
              <DashboardInformationIcon />
            </div>
            <span className="ff-inter c-1e429f fs-0_875rem lh-1_25rem">
              https://www.biteunite.com/sharedkitchen-hong-kong -{' '}
              <span className="fw-600">0.55</span>
            </span>
          </div>
          <div className="d-flex-row w-100">
            <div className="d-flex-center mr-0_75rem">
              <DashboardInformationIcon />
            </div>
            <span className="ff-inter c-1e429f fs-0_875rem lh-1_25rem">
              https://cloud.biteunite.com/index/i-love-babycakes -{' '}
              <span className="fw-600">0.11</span>
            </span>
          </div>
        </div>
        <div className="d-flex-row w-100 p-0_5rem-0">
          <span className="ff-inter fs-0_875rem lh-1_25rem c-888e9e">
            While it might be a one-time drop, it's best to check content
            quality. Examine the page list in the Position <br /> Tracking tool
            and consider edits.{' '}
            <span className="td-underline">Position Tracking ↗</span>
          </span>
        </div>
      </div>
      <div className="d-flex-center mt-1rem">
        <img src={VisibilityDropPNG} alt="" className="w-12rem" />
      </div>
    </div>
  );
};

export default VisibilityDrop;
